import './App.css';
import React, { Suspense,useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Shimmer from "./UI/Shimmer/Shimmer";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { initCosmos } from "./cosmosTracking";
import {handleDeeplink,getQuery} from "./utils"
import i18n from './i18n';

const Header = React.lazy(() =>import("./components/Header/Header"));
const CurrentPlan = React.lazy(() => import("./components/CurrentPlan/CurrentPlan"));
const ModifyPlan = React.lazy(() => import("./components/ModifyPlan/ModifyPlan"));

function App() {
  let orgName=localStorage.getItem('orgName')

  useEffect(() => {
    let defaultLanguage = getQuery()['defaultLanguage'];
    if(defaultLanguage) {
      i18n.changeLanguage(defaultLanguage)
    }
    initCosmos();
  },[])

  return (
    <div className="App">
      <Suspense fallback={<Shimmer />}>
        <BrowserRouter>
            <Header text={orgName} back onClick={()=>{ handleDeeplink("UTIL_WEBVIEW_CLOSE")}} rightText={"T&C"}/>
            <Switch>
                <Route exact path="/" component={() => <CurrentPlan/>}/>
                <Route exact path="/modifyPlan" component={() => <ModifyPlan />} />
                <Route render={() => <Redirect to="/" />} />
            </Switch>
          </BrowserRouter>
        <ToastContainer
        limit={2}
          style={{ fontSize: "16px" }}
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Suspense>
    </div>
  );
}

export default App;
