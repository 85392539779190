export function getQuery() {
	let b = window.location.search
		.slice(1)
		.split("&")
		.map((qStr) => qStr.split("="))
		.reduce((acc, inc) => {
			acc[inc[0]] = inc[1];
			return acc;
		}, {});

	return b;
}

export function appendToken(url) {
	return url + `?token=${getQuery()[`token`]}`
  }

export function getParamLength() {
	let paramLength = Object.keys(getQuery()).length
	// if (!getQuery["token"] && !!window.location.search) {
	// 	return paramLength + 1
	// }
	return paramLength
}

export function getParam(data) {
	let urlParam = new URLSearchParams(window.location.search)
	let query = Array.isArray(data) ? data : [data]
	query.forEach((el, index) => {
		if (urlParam.has(el)) {
			urlParam.delete(el)
		} else {
			urlParam.append(el, true)
		}
	})
	if (getQuery["token"]) {
		return urlParam.toString()
	}
	return "?" + urlParam.toString();
}

// export function deeplink(screen, paramOne, paramTwo, paramThree) {
// 	if (screen == "UTIL_WEBVIEW_CLOSE") {
// 		window?.mobile?.setTestState(``);
// 		window?.webkit?.messageHandlers?.stateEvents?.postMessage('QUIT');
// 		return
// 		// window?.webkit?.messageHandlers?.onDeeplinkExecuted?.postMessage("");
// 		// window?.mobile?.onDeeplinkExecuted("");
// 	}
// }

export const handleDeeplink = (screen, paramOne, paramTwo, paramThree) => {
	console.log(screen)
	let param = screen && paramOne && paramTwo && paramThree
		? `${screen},${paramOne},${paramTwo},${paramThree}`
		: screen && paramOne && paramTwo
			? `${screen},${paramOne},${paramTwo}`
			: screen && paramOne
				? `${screen},${paramOne}`
				: screen
					? `${screen}`
					: paramOne;
	if (screen == "UTIL_WEBVIEW_CLOSE") {
		window?.mobile?.onDeeplinkExecuted(`quit`);
		window?.webkit?.messageHandlers?.stateEvents?.postMessage('QUIT');
		return
	}
	// alert(JSON.stringify(param))
	// alert(param)
	// console.log(param,JSON)
	if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
		window.mobile.onDeeplinkExecuted(param);
	}
	if (
		window &&
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.onDeeplinkExecuted
	) {
		window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(param);
	}

}

export const handleDeeplinkExecutedV2 = (jsonVal) => {
	console.log(jsonVal)
	if (window && window.mobile && window.mobile.onDeeplinkExecutedV2) {
		window.mobile.onDeeplinkExecutedV2(jsonVal);
	}
	if (
		window &&
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.onDeeplinkExecutedV2
	) {
		window.webkit.messageHandlers.onDeeplinkExecutedV2.postMessage(jsonVal);
	}
}

export const defaultToken =
"eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6Mjg5OTk0LCJvcmdJZCI6MTcwLCJuYW1lIjoiR3VuamFuIEd1cHRhIiwiZW1haWwiOiJndW5qYW5AY2xhc3NwbHVzYXBwcC5jb20iLCJtb2JpbGUiOiI5MTk3MTExNTMwNzkiLCJ0eXBlIjozLCJpYXQiOjE2MzEwMDM4MDgsImV4cCI6MTYzMTYwODYwOH0.CRN0E7wgYZ1MYfliZT2ObR1JbWRSq_2GmJJYF0U4jYaOtgTY_DJWI4mSIzu-ZSAI"

export const monthConverter=(data)=>{
		let duration=''
	  if(data <12 ){
		  duration=data + " " + "Month(s)"
	  }
	  else{
		if(data % 12 != 0){
		  duration=Math.floor(data/12) +" " + "Year(s)" + " " + (data%12).toFixed() +" " + "Month(s)"
		}
		else{
		duration=data/12 + " "+"Year(s)"
		  }
	  }
	return duration
	}
	